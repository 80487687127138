import * as React from "react";

import Grid from '@mui/material/Grid';
import CardTitle from "../CardTitle/CardTitle";
import CardBasic, { contextMap, getRichText } from "../CardBasic/CardBasic";

// -----------------------------------------------------------

const Tenant: React.FC<{ tenantJson: any, categoriesJson: any }> = ({ tenantJson, categoriesJson }) => {

  if (categoriesJson == null) {
    categoriesJson = [];
  }

  let tenantId = tenantJson.tenant_id;
  let tenantTitle = tenantJson.tenant_title;
  let tenantDescriptionHtml = tenantJson.tenant_description_html;

  return (

    <Grid container spacing={5}>  {/* MAIN GRID */}

      <Grid item xs={12} sm={8} maxWidth={900}>       {/* FIRST COLUMN */}

        <Grid container spacing={2}>

          <Grid item xs={12}>
            <CardTitle title={tenantTitle} descriptionHtml={tenantDescriptionHtml} linkTo={'/tenant/' + tenantId} imageUrl={tenantJson.tenant_logo_image_url} cardDisplay={tenantJson.card_display} context={contextMap.TENANT_TITLE}></CardTitle>
          </Grid>

          {
            categoriesJson.map(categoryJson => (
              <Grid item xs={12} sm={6}>
                <CardBasic title={categoryJson.category_title} linkTo={'/tenant/' + tenantId + '/category/' + categoryJson.category_id} imageUrl={categoryJson.category_logo_image_url} cardDisplay={categoryJson.card_display} context={contextMap.CATEGORY_TITLE}></CardBasic>
              </Grid>
            ))
          }

        </Grid>
      </Grid>                                         {/* END FIRST COLUMN */}

    </Grid>

  )
}

export default Tenant