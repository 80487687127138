import * as React from "react";
import Layout from "../components/Layout/Layout";
import Tenant from "../components/Tenant/Tenant";

const TenantBasicUsage = (props: { pageContext: any; }) => {
  const { pageContext } = props

  var siteJson = pageContext.site;
  if (siteJson == null) {
    siteJson = new Object();
  }

  var tenantJson = pageContext.tenant;
  if (tenantJson == null) {
    tenantJson = new Object();
  }

  let siteTitle = siteJson.site_title;
  let tenantTitle = tenantJson.tenant_title;
  let tenantId = tenantJson.tenant_id;
  let categoriesJson = tenantJson.categories;

  return (
    <Layout
      dynamicMenuItems={[]}
      pageTitle={tenantTitle}
      siteHeaderLogoImageUrl={siteJson.site_header_logo_image_url}
      siteTitle={siteTitle}
      tenantId={tenantId}
    >

      <Tenant tenantJson={tenantJson} categoriesJson={categoriesJson}></Tenant>


    </Layout>
  )
}
export default TenantBasicUsage