import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea } from '@mui/material';
import { Link } from "gatsby-theme-material-ui";
import Grid from '@mui/material/Grid';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { contextMap, getBackgroundColor, getRichContent } from "../CardBasic/CardBasic";

// -----------------------------------------------------------

const CardTitle: React.FC<{ title: string, descriptionHtml: string, linkTo: string, imageUrl: string, cardDisplay: any, context: string }> = ({ title, descriptionHtml, linkTo, imageUrl, cardDisplay, context }) => {
  var bgcolor = getBackgroundColor(context);

  // default title
  var minHeight = 100;
  var maxWidth = 900;
  var boxMaxWidth = 425;

  return (

    <Card sx={{ display: 'flex', bgcolor: bgcolor, height: "100%", minHeight: minHeight, maxWidth: maxWidth, alignItems: "center" }}>
      <CardActionArea component={Link} to={linkTo}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          {getBasicGrid(context, title, boxMaxWidth, imageUrl, cardDisplay, descriptionHtml)}
        </CardContent>
      </CardActionArea>
    </Card>

  )
}

export default CardTitle

function getBasicGrid(context: string, title: string, boxMaxWidth: number, imageUrl: string, cardDisplay: any, descriptionHtml: string): React.ReactNode {

  if (cardDisplay.includes('image')) {
    return getTitleGrid(title, boxMaxWidth, imageUrl, descriptionHtml);
  }

  if (context == contextMap.SITE_HEADER) {
    return getTitleAndDescriptionGrid(title, descriptionHtml);
  } else if (context == contextMap.TENANT_TITLE) {
    return getTitleAndDescriptionGrid(title, descriptionHtml);
  } else if (context == contextMap.CATEGORY_TITLE) {
    return getTitleAndDescriptionGrid(title, descriptionHtml);
  } else {
    return getTitleAndDescriptionGrid(title, descriptionHtml);
  }

  function getTitleGrid(title: string, boxMaxWidth: number, imageUrl: string, descriptionHtml: string): React.ReactNode {
    var imageDivWidth = 300;
    
    if (imageUrl != null) {

      return (
        <Grid alignItems="center" container spacing={2}>
          <Grid item xs={6} textAlign="left" style={{}}>
            <Typography component="div" variant="h4" sx={{ fontWeight: 'bold' }}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{}}>
            <Box display="flex" maxWidth={boxMaxWidth} height={"auto"} >
              <img src={imageUrl} width={"100%"} height={"auto"}></img>
            </Box>
          </Grid>
          <Grid item xs={12} style={{}}>            
            {getRichContent(descriptionHtml)}
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid alignItems="center" container spacing={2}>
        <Grid item xs={6} textAlign="left" style={{}}>
          <Typography component="div" variant="h4" sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{}}>
          <Box display="flex" maxWidth={boxMaxWidth} height={"auto"} >
              <img src={imageUrl} width={"100%"} height={"auto"}></img>
          </Box>
        </Grid>
        <Grid item xs={12} style={{}}>
             {getRichContent(descriptionHtml)}
        </Grid>
      </Grid>
    )
  }
}

function getTitleAndDescriptionGrid(title: string, descriptionHtml: string): React.ReactNode {
  
  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid item xs={12} textAlign="left" style={{}}>
        <Typography component="div" variant="h4" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{}}>
        <Typography component="div" variant="body1">
          {getRichContent(descriptionHtml)}
        </Typography>
      </Grid>
    </Grid>
  )
}



export function getTitleOnlyGrid(title: string): React.ReactNode {
    return (
      <Grid alignItems="center" container spacing={2}>
        <Grid item xs={12} textAlign="left" style={{}}>
          <Typography component="div" variant="h4" sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
        </Grid>
      </Grid>
    )
  }